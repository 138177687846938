export const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp);

  return `${date.toLocaleDateString(undefined, {
    weekday: 'long',
  })}, ${date.toLocaleDateString(undefined, {
    month: 'long',
  })} ${date.toLocaleDateString(undefined, {
    day: 'numeric',
  })}`;
};
