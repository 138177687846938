import React from 'react';
import { FormattedMessage } from 'react-intl';

export const MeetingListHeaderUploading: React.FC = () => {
  return (
    <div className="border-slate-200 border-tbg-slate-50 px-8 py-2 text-left font-semibold">
      <FormattedMessage defaultMessage="Uploading:" id="Uvjqex" />
    </div>
  );
};
