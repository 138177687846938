import React from 'react';
import { FormOverride } from './JsonForm';
import { FormattedMessage } from 'react-intl';

const linear: FormOverride = {
  config: [
    {
      path: 'team_id',
      label: () => <FormattedMessage defaultMessage="Team" />,
    },
  ],
  parameters: [
    {
      path: 'title',
      label: () => <FormattedMessage defaultMessage="Title" />,
    },
    {
      path: 'description',
      label: () => <FormattedMessage defaultMessage="Description" />,
    },
  ],
};

const notion: FormOverride = {
  config: [
    {
      path: 'database_id',
      label: () => <FormattedMessage defaultMessage="Database" />,
    },
  ],
  parameters: [
    {
      path: 'children',
      label: () => <FormattedMessage defaultMessage="Page content" />,
    },
    {
      path: 'properties',
      recursive: true,
      label: () => <FormattedMessage defaultMessage="Database" />,
    },
  ],
};

const slack: FormOverride = {
  config: [
    {
      path: 'conversationId',
      label: () => <FormattedMessage defaultMessage="Channel" />,
    },
  ],
  parameters: [
    {
      path: 'text',
      label: () => <FormattedMessage defaultMessage="Message" />,
    },
  ],
};

const hubspot: FormOverride = {
  parameters: [
    {
      path: 'hs_meeting_body',
      label: () => <FormattedMessage defaultMessage="Meeting Notes" />,
    },
  ],
};

const pipedrive: FormOverride = {
  parameters: [
    {
      path: 'note',
      label: () => <FormattedMessage defaultMessage="Meeting Notes" />,
    },
  ],
};

// NOTE: Fixed parameters should be added in processFieldConversions function in backend
// should only add parameters that customers will interact with
export const JsonFormOverrides: Record<string, FormOverride | undefined> = {
  linear,
  notion,
  slack,
  hubspot,
  pipedrive,
};
