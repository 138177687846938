import { LabelConfig } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, getColourByName } from '../../../components/Avatar';
import { Chip } from '../../../components/Chips';
import { removeMeetingLabel } from '../../../graphql/meetings';
import { UserSpace } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { isMeetingOwner } from '../../../helpers/meetings';
import { AnyMeeting } from '../../../models/meeting';
import { removeLabelFromMeeting } from '../../../redux/modules/global';
import {
  selectTeam,
  selectUid,
  selectUserName,
  selectUserSpaces,
} from '../../../redux/selectors';
import { useSearchQueryState } from '../../../services/Search';
import { Label } from '../../Common/Label';
import { MeetingActions } from '../card/MeetingActions';
import { UPLOAD_MAX_PROCESSING_TIME_MS } from '../card/MeetingCard';
import { createStopPropagationHandler } from '../common/stopPropagation';

export const MeetingListItem: React.FC<{
  meeting: AnyMeeting;
  readOnly?: boolean;
  checkboxColumnVisible: boolean;
  actionsVisible: boolean;
}> = ({ meeting, readOnly, checkboxColumnVisible, actionsVisible }) => {
  const userId = useSelector(selectUid);
  const userName = useSelector(selectUserName);
  const userSpaces = useSelector(selectUserSpaces);
  const [, setFilter] = useSearchQueryState();
  const dispatch = useDispatch();
  const intl = useIntl();
  const team = useSelector(selectTeam);

  const onRemoveLabel = async (label: LabelConfig) => {
    dispatch(
      removeLabelFromMeeting({ meetingId: meeting.id, labelId: label.id })
    );
    await removeMeetingLabel(meeting.id, label.id);
    trackWebEvent('Transcript Label Removed', { team_id: team?.id });
  };

  const isPreview = meeting.isPreview ?? false;
  const isArchived = !!meeting.archivedAt;

  const isReadOnly = readOnly || isPreview || isArchived;

  const isOwner = isMeetingOwner(userId, meeting);
  const hadProcessingError =
    meeting.hadProcessingError ||
    (meeting.isUploading &&
      meeting.modified < Date.now() - UPLOAD_MAX_PROCESSING_TIME_MS);
  const isActiveUpload =
    typeof meeting.uploadProgress !== 'undefined' &&
    meeting.uploadProgress >= 0 &&
    meeting.uploadProgress < 100;

  const otherParticipants = meeting.participants.filter(
    (p) => p && p.name !== userName
  );
  otherParticipants.sort(
    (a, b) => b.analytics.textLength - a.analytics.textLength
  );
  const avatarParticipants = otherParticipants.length
    ? otherParticipants[0]
    : meeting.participants.length
      ? meeting.participants[0]
      : undefined;
  const avatarParticipantsSrc =
    avatarParticipants &&
    team?.members.find((m) => m.displayName === avatarParticipants.name)
      ?.photoURL;

  const spaces = (meeting.permissions?.allow.spaces
    ?.map((s) => userSpaces.find((us) => us.id === s))
    .filter(Boolean) || []) as UserSpace[];

  const onMeetingCardClick = useCallback(() => {
    if (hadProcessingError) {
      return null;
    }
    if (meeting.isUploading || isActiveUpload) {
      return () =>
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: 'The meeting has not finished processing yet',
            id: 'UeRUCB',
          }),
          { variant: 'WARNING' }
        );
    }
    return true;
  }, [intl, hadProcessingError, isActiveUpload, meeting.isUploading]);

  return (
    <a
      {...(!isActiveUpload && !meeting.isUploading
        ? { href: `/#/transcripts/${meeting.id}` }
        : {
            onClick: onMeetingCardClick,
          })}
      onClick={onMeetingCardClick}
      className="flex items-center border-slate-200 border-t bg-white py-2 pr-4 text-sm transition-all duration-200 hover:bg-slate-50/80"
    >
      {checkboxColumnVisible && (
        <div className="w-8 min-w-8 max-w-8">&nbsp;</div>
      )}

      <div className="flex w-12 min-w-12 max-w-12 flex-col items-center">
        <div className="font-medium">
          {new Date(meeting.created).toLocaleTimeString(undefined, {
            hour: 'numeric',
            minute: '2-digit',
          })}
        </div>
        <div className="text-slate-500 text-sm">
          {Math.floor((meeting.speechDuration || meeting.duration) / 60)}m
        </div>
      </div>

      <div className="mx-2 w-10 min-w-10 max-w-10 justify-center">
        <Avatar
          src={avatarParticipantsSrc}
          name={avatarParticipants?.name ?? ''}
          twoLetters={true}
          color={getColourByName(avatarParticipants?.name ?? '')}
        />
      </div>

      <div className="flex min-w-0 flex-shrink flex-grow flex-col">
        <h3 className="truncate font-semibold">{meeting.title}</h3>
        <div className="max-w-full gap-2 truncate text-slate-500 text-sm">
          {meeting.participants
            .map((participant, idx) => participant.name)
            .join(', ')}
        </div>
      </div>

      {actionsVisible && (meeting.labels?.length > 0 || spaces.length > 0) ? (
        <div className="space-between ease hidden flex-shrink-[1.5] flex-grow gap-2 overflow-hidden transition-width md:flex [&:not(:empty)]:min-w-[150px] [&:not(:empty)]:hover:flex-shrink-[0.25]">
          <div className="flex min-w-0 flex-grow" />
          {meeting.labels?.map((l) => (
            <div key={l.id} className="min-w-0">
              <Label
                {...l}
                onClick={() => setFilter({ labels: [l.name] })}
                onDelete={
                  !l.isAuto && isOwner && !isReadOnly
                    ? () => onRemoveLabel(l)
                    : undefined
                }
                className="min-w-0 bg-opacity-100"
              />
            </div>
          ))}
          {spaces.map((space) => (
            <div key={space.id} className="min-w-0">
              <Chip
                onClick={() =>
                  createStopPropagationHandler(() =>
                    setFilter({ spaces: [space.id] })
                  )
                }
                // onDelete={!l.isAuto && isOwner ? () => onRemoveLabel(l) : undefined}
                className="h-[100%] min-w-0 bg-opacity-100"
              >
                {space.icon} {space.name}
              </Chip>
            </div>
          ))}
        </div>
      ) : null}

      {actionsVisible && (
        <MeetingActions
          meeting={meeting}
          fullSize={false}
          className="hidden items-center justify-end lg:flex"
        />
      )}
    </a>
  );
};
