import slack from '../../../img/slackIcon.svg';
import notion from '../../../img/notion.png';
import linear from '../../../img/linear.png';
import hubspot from '../../../img/hubspot.png';
import pipedrive from '../../../img/pipedrive.png';

export const AllIntegrations_IntegrationApp = [
  {
    id: 'slack',
    icon: slack,
  },
  {
    id: 'notion',
    icon: notion,
  },
  {
    id: 'linear',
    icon: linear,
  },
  {
    id: 'hubspot',
    icon: hubspot,
  },
  {
    id: 'pipedrive',
    icon: pipedrive,
  },
];
