import { logger, MeetingShareType } from '@tactiq/model';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useStore } from 'react-redux';
import { Button } from '../../../../components/buttons';
import {
  MeetingShare,
  UserIntegrationConnection,
} from '../../../../graphql/operations';
import { trackWebEvent } from '../../../../helpers/analytics';
import { copyToClipboard } from '../../../../helpers/clipboard';
import { IntegrationImplementation } from '../../../../models/integration';
import { getMeeting } from '../../../../redux/sagas/getMeeting';
import { RootState, TactiqStore } from '../../../../redux/store';
import { AllIntegrations } from '../../../Integration/integrations/index';
import { useMeeting } from '../../common/meeting-hooks';
import { createStopPropagationHandler } from '../../common/stopPropagation';
import { ShareCardWrapper } from './ShareCardWrapper';
import { ClipboardCopy, CloudOff, ExternalLink } from 'lucide-react';
import { Tooltip } from '../../../../components/Tooltip';
import { ConfirmActionDialog } from '../../../../components/modals';
import { AllIntegrations_IntegrationApp } from '../../../Integration/integrations/integration-app';

const useShareActions = (share: MeetingShare) => {
  const actions = [];

  actions.push(
    <Tooltip
      key="copylink"
      title={
        <FormattedMessage
          defaultMessage="Copy link to clipboard"
          id="XXSgk9"
          description="Copy transcript share link to clipboard tooltip."
        />
      }
    >
      <Button
        size="tiny"
        variant="icon"
        onClick={createStopPropagationHandler(() => {
          copyToClipboard(share.link, 'Sharing - Copy link to clipboard').catch(
            logger.error
          );
          trackWebEvent('Sharing - Copy link to clipboard');
        })}
      >
        <ClipboardCopy strokeWidth={2} size={18} className="text-slate-600" />
      </Button>
    </Tooltip>
  );

  actions.push(
    <Tooltip
      key="open"
      title={
        <FormattedMessage
          defaultMessage="Open"
          id="t0v9/t"
          description="Open transcript share link button tooltip."
        />
      }
    >
      <Button variant="icon" size="tiny" href={share.link} target="_blank">
        <ExternalLink strokeWidth={2} size={18} className="text-slate-600" />
      </Button>
    </Tooltip>
  );

  return actions;
};

const ShareCardV2: React.FC<{
  share: MeetingShare;
}> = ({ share }) => {
  const intl = useIntl();
  const actions = useShareActions(share);

  const integration = AllIntegrations_IntegrationApp.find(
    (x) => x.id === share.integrationId
  );
  if (!integration) return null;

  return (
    <ShareCardWrapper
      key={share.externalId}
      imageUrl={integration.icon}
      title={
        share.title
          ? share.title
          : intl.formatMessage(
              {
                defaultMessage: 'Shared on {integration}',
              },
              {
                integration: integration.id,
              }
            )
      }
      actions={actions}
    />
  );
};

const ShareCardV1: React.FC<{
  share: MeetingShare;
}> = ({ share }) => {
  const meeting = useMeeting();
  const store = useStore() as unknown as TactiqStore;
  const integration = AllIntegrations.find((x) => x.id === share.integrationId);
  const connections = useSelector((state: RootState) => state.user.connections);
  const connection = connections?.find(
    (c) => c.connectionKey === share.connectionKey
  );

  const [isDeleting, setDeleting] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const actions = [...useShareActions(share)];

  if (integration?.unshare) {
    actions.unshift(
      <Tooltip
        key="unshare"
        title={
          <FormattedMessage
            defaultMessage="Stop sharing"
            id="TriUbq"
            description="Stop transcript sharing button label."
          />
        }
      >
        <Button
          size="tiny"
          variant="icon"
          onClick={createStopPropagationHandler(() => {
            setDeleteDialogOpen(true);
          })}
        >
          <CloudOff strokeWidth={2} size={18} className="text-slate-600" />
        </Button>
      </Tooltip>
    );
  }

  if (!integration) return null;

  return (
    <div>
      <ShareCardWrapper
        key={share.externalId ?? share.sharedAt}
        imageUrl={integration?.icon}
        title={getIntegrationLabel(share, integration, connection)}
        actions={actions}
      />
      {integration?.unshare ? (
        <ConfirmActionDialog
          open={isDeleteDialogOpen}
          title={
            <FormattedMessage
              defaultMessage="Stop sharing the meeting?"
              id="zlXI5D"
              description="Stop sharing the meeting dialog title."
            />
          }
          text={
            <FormattedMessage
              defaultMessage="Users who still have the link will be no-longer be able to view it."
              id="riDFzX"
              description="Stop sharing the meeting dialog content."
            />
          }
          onNo={() => setDeleteDialogOpen(false)}
          no={
            <FormattedMessage
              defaultMessage="Cancel"
              id="PyxZY2"
              description="Button label"
            />
          }
          yesProps={{ loading: isDeleting, color: 'error' }}
          onYes={async () => {
            if (!isDeleting && meeting) {
              setDeleting(true);
              if (integration.unshare) {
                trackWebEvent('Sharing - Unshare');
                await integration.unshare(meeting.id, share);
              }
              setDeleteDialogOpen(false);
              await getMeeting(store, meeting.id, meeting, { force: true });
            }
          }}
          yes={
            <FormattedMessage
              defaultMessage="Stop Sharing"
              id="TepxgA"
              description="Button label"
            />
          }
        />
      ) : null}
    </div>
  );
};

function getIntegrationLabel(
  transcript: MeetingShare,
  integration: IntegrationImplementation,
  connection?: UserIntegrationConnection | null
) {
  if (!connection) {
    return integration?.getSharingDestinationTitle
      ? `${integration?.title} - ${integration?.getSharingDestinationTitle(
          transcript.options
        )}`
      : integration?.title;
  } else if (integration?.getSharingDestinationTitle) {
    return `${integration?.title} - ${integration?.getSharingDestinationTitle(
      transcript.options
    )}`;
  } else {
    return `${integration?.title} - ${connection.name}`;
  }
}

export const ShareCard: React.FC<{
  share: MeetingShare;
}> = ({ share }) => {
  return share.type === MeetingShareType.INTEGRATION_APP ? (
    <ShareCardV2 share={share} />
  ) : (
    <ShareCardV1 share={share} />
  );
};
