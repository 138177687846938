import React, { ReactElement, useState } from 'react';
import { Button } from '../../components/buttons';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchFilterInput, SortBy, Workflow } from '../../graphql/operations';
import { Tooltip } from '../../components/Tooltip';
import { SearchPopup } from '../Transcripts/common/SearchBar';
import { useWorkflowRun } from '../../services/Workflow';
import { PlayIcon } from 'lucide-react';
import { DuplicateWorkflowButton } from './DuplicateWorkflowButton';
import { useCurrentUserId } from '../../services/User';
import CopyWorkflowLinkButton from './CopyWorkflowLinkButton';

/**
 * Wraps up all the render logic required to be able to perform actions on a workflow.
 * - are you allowed to run this workflow
 * - should it save before trying to run
 */
export function WorkflowActionsButton(props: {
  workflow: Workflow;
  preview?: boolean;
  /** Called once the workflowRun request returns */
  onSuccess: (next: { meetingId: string; executionId: string }) => void;
  /** If a meetingId is not provided, the search box will pop up instead
   * not used anywhere this component is rendered, probably can remove it
   */
  meetingId?: string;
  onSave?: () => Promise<void>;
  loading?: boolean;
  hasUnsavedChanges?: boolean;
}): ReactElement {
  const {
    workflow,
    preview,
    hasUnsavedChanges,
    onSave,
    onSuccess,
    loading,
    meetingId,
  } = props;
  const { canRun, id } = workflow;
  const workflowRun = useWorkflowRun(onSave ? 'editor' : 'meeting');
  const [showMeetingSearch, setShowMeetingSearch] = useState(false);
  const intl = useIntl();
  const isUserWorkflowOwner = workflow.createdBy.uid === useCurrentUserId();

  const workflowDuplicateMessage = intl.formatMessage({
    description:
      'Duplicate a workflow. Tooltip text for a user who is not the owner of the workflow.',
    defaultMessage:
      'To modify this workflow, or create a copy for yourself, duplicate it.',
  });
  const runTooltipMessage = intl.formatMessage({
    description: 'Tooltip text for the workflow being runnable',
    defaultMessage: 'Run the workflow',
  });

  const notAllowedRunTooltipMessage = intl.formatMessage({
    description: 'Tooltip text for the workflow not being runnable',
    defaultMessage: 'This workflow cannot be run',
  });

  async function onRun(selectedMeetingId: string) {
    setShowMeetingSearch(false);
    if (onSave) await onSave();
    const execution = await workflowRun.request({
      input: { id, meetingId: selectedMeetingId },
    });
    const executionId = execution.data?.runWorkflow.id;

    if (executionId) onSuccess({ meetingId: selectedMeetingId, executionId });
  }

  const runButtonWithTooltip = (
    <Tooltip
      title={canRun ? runTooltipMessage : notAllowedRunTooltipMessage}
      arrow
      placement="bottom-end"
    >
      <div className="text-slate-400 text-sm">
        <Button
          className="h-8"
          variant={canRun ? 'filled' : 'secondaryOutline'}
          loading={workflowRun.loading}
          disabled={!canRun}
          onClick={() =>
            meetingId ? onRun(meetingId) : setShowMeetingSearch(true)
          }
          startIcon={<PlayIcon size="1rem" />}
        >
          <FormattedMessage defaultMessage="Run" id="KiXNvz" />
        </Button>
      </div>
    </Tooltip>
  );

  const saveButton = onSave && (
    <Button
      variant="secondaryOutline"
      className="h-8"
      loading={loading}
      disabled={!hasUnsavedChanges}
      onClick={() => onSave()}
      startIcon={
        hasUnsavedChanges ? (
          <div className="size-1.5 rounded-full bg-brand" />
        ) : undefined
      }
    >
      <FormattedMessage defaultMessage="Save" />
    </Button>
  );

  const duplicateButtonWithTooltip = (
    <Tooltip title={workflowDuplicateMessage} arrow placement="bottom-end">
      <div className="text-slate-400 text-sm">
        <DuplicateWorkflowButton
          id={props.workflow.id}
          variant={canRun ? 'secondaryOutline' : 'filled'}
        />
      </div>
    </Tooltip>
  );

  const { primaryAction, secondaryAction, tertiaryAction } = getActions({
    isUserWorkflowOwner,
    canRun,
    runButtonWithTooltip,
    duplicateButtonWithTooltip,
    preview,
    copyLinkToShareButton: (
      <CopyWorkflowLinkButton
        workflow={workflow}
        source="preview"
        className="h-8"
        withMessage
      />
    ),
  });

  return (
    <>
      {showMeetingSearch && (
        <MeetingSearch
          open={showMeetingSearch}
          setOpen={setShowMeetingSearch}
          onSelectMeeting={onRun}
        />
      )}
      <div className="flex items-center gap-x-2">
        {props.workflow.canModify && saveButton}
        {tertiaryAction}
        {secondaryAction}
        {primaryAction}
      </div>
    </>
  );
}

function MeetingSearch(props: {
  open: boolean;
  setOpen: (next: boolean) => void;
  onSelectMeeting: (meetingId: string) => void;
}) {
  const [query, setQuery] = useState<SearchFilterInput | undefined>({
    query: '',
  });
  const [sortBy, setSortBy] = useState<SortBy | undefined>(
    SortBy.CREATED_NEWEST_FIRST
  );

  return (
    <div className="absolute top-[10vh] right-0 left-0 m-auto w-full min-w-md max-w-lg md:left-64">
      <SearchPopup
        isOpen={props.open}
        onClose={() => props.setOpen(false)}
        query={query}
        skipEmpty={false}
        setQuery={setQuery}
        sortBy={sortBy}
        setSortBy={setSortBy}
        onSelectMeeting={(meeting) => props.onSelectMeeting(meeting.id)}
      />
    </div>
  );
}

function getActions({
  isUserWorkflowOwner,
  canRun,
  runButtonWithTooltip,
  duplicateButtonWithTooltip,
  preview,
  copyLinkToShareButton,
}: {
  isUserWorkflowOwner: boolean;
  canRun: boolean;
  runButtonWithTooltip: JSX.Element;
  duplicateButtonWithTooltip: JSX.Element;
  preview: boolean | undefined;
  copyLinkToShareButton: JSX.Element;
}): {
  primaryAction: JSX.Element;
  secondaryAction: JSX.Element;
  tertiaryAction: JSX.Element;
} {
  // owner: run is the primary action, no secondary action
  // not owner: run and duplicate are both shown, with one being primary and the other secondary
  //            primary: run if canRun, duplicate if not
  //            secondary: duplicate if canRun, null if not
  let primaryAction;
  let secondaryAction;
  let tertiaryAction;

  if (isUserWorkflowOwner) {
    primaryAction = runButtonWithTooltip;
    secondaryAction = null;
  } else {
    if (canRun) {
      primaryAction = runButtonWithTooltip;
      secondaryAction = duplicateButtonWithTooltip;
    } else {
      primaryAction = duplicateButtonWithTooltip;
      secondaryAction = null;
    }
  }

  // if the workflow is in preview mode, we need to adjust the primary and secondary actions
  // if the user is the owner of the workflow "template"
  if (preview) {
    // hide the run button for preview mode if not runnable
    primaryAction = !canRun ? null : primaryAction;
    // if the current user owns the workflow "template" and is in preview mode, show the duplicate button
    secondaryAction = duplicateButtonWithTooltip;
    tertiaryAction = copyLinkToShareButton;
  }

  return {
    primaryAction: primaryAction ?? <></>,
    secondaryAction: secondaryAction ?? <></>,
    tertiaryAction: tertiaryAction ?? <></>,
  };
}
