import {
  DataCollectionListResponse,
  IntegrationAppClient as IntegrationAppClientSdk,
} from '@integration-app/sdk';
import { IntegrationAppClient as IntegrationAppClientReact } from '@integration-app/react';

export async function listAllFromIntegration(
  // didn't quite liked that but the api client types are different from the sdk and react
  api: IntegrationAppClientSdk | IntegrationAppClientReact,
  connectionType: string,
  collectionKey: string
): Promise<{ records: DataCollectionListResponse['records'] }> {
  let items: DataCollectionListResponse['records'] = [];
  let fetchMore = true;
  let cursor: string | undefined;

  while (fetchMore) {
    const response = await api
      .connection(connectionType)
      .dataCollection(collectionKey)
      .list({ cursor });

    items = items.concat(response.records);

    if (!response.cursor || cursor === response.cursor) {
      fetchMore = false;
    } else {
      cursor = response.cursor;
    }
  }

  return { records: items };
}
