import React, { ReactElement, useState, useEffect, useId } from 'react';
import { useReactFlow } from '@xyflow/react';
import { SingleSourceNode, WorkflowNodeProps } from './BaseNode';
import { Select } from '../../../components/Select';
import {
  ArrowRightLeft,
  Ellipsis,
  ExternalLink,
  Mail,
  RefreshCcw as RefreshIcon,
  Blocks as ShareIcon,
} from 'lucide-react';
import logo from '../../../img/tactic-logomark.svg';
import { JsonFormOverrides } from '../JsonFormOverrides';
import {
  useDataSource,
  useConnectionCreate,
  useConnectionList,
  useConnectionDisconnect,
  useIntegrationGet,
  DataSchema,
  useDataCollectionList,
  useDataSourceUpdate,
  useDataSourceRefresh,
} from '../../../services/Integration';
import { Button } from '../../../components/buttons';
import { useWorkflowId } from '../WorkflowIdContext';
import { Chip } from '../../../components/Chips';
import { Menu } from '../../../components/Menu';
import { Spinner } from '../../../components/Spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import { enqueueSnackbar } from 'notistack';
import { JsonForm, UiSchemaItem, UiSchema } from '../JsonForm';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { useIsPreview } from './useIsPreview';
import { Combobox } from '../../../components/Combobox';
import { kIntegrationRequest } from '../../../helpers/routes';
import { jsonSchemaGet } from '@tactiq/model';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';

const REQUEST_INTEGRATION = 'request-integration';

export function SendData(
  props: WorkflowNodeProps<{
    type: string;
    fieldData: Record<string, unknown>;
    parameterData?: Record<string, unknown>;
    email?: {
      subject: string;
      message: string;
    };
  }>
): ReactElement {
  const { id, data } = props;
  const { type, fieldData = {}, parameterData } = data;
  const { updateNodeData } = useReactFlow();
  const { workflowId } = useWorkflowId();
  const isPreview = useIsPreview();
  const intl = useIntl();
  const instanceKey = `${workflowId}::${id}`;

  const optionsData = useConnectionList();
  const disconnect = useConnectionDisconnect(type);
  const options = optionsData.data?.items ?? [];
  const autocomplete = useAutocomplete(props);

  const isExecution = Boolean(props.data.execution);

  const connections = new Map(options.map((opt) => [opt.key, opt.connection]));
  const connection = connections.get(type);

  const isConnected =
    connection && !connection.error && !connection.disconnected;

  const dataSource = useDataSource(
    isConnected ? { type, instanceKey } : undefined
  );

  const { collectionKey, collectionParameters, collectionSpec } =
    dataSource.data ?? {};
  const { fieldsSchema, parametersSchema } = collectionSpec ?? {};

  const formOverrides = JsonFormOverrides[type];

  useEffect(() => {
    if (collectionParameters && !isEqual(parameterData, collectionParameters)) {
      updateNodeData(id, { parameterData: collectionParameters });
    }
  }, [updateNodeData, id, parameterData, collectionParameters]);

  return (
    <SingleSourceNode
      minHeight={132}
      workflowNode={props}
      icon={
        <ShareIcon className="size-8 rounded-lg border border-amber-500/25 bg-amber-50 p-1.5 text-amber-500" />
      }
      contentClassName={'h-full'}
    >
      {optionsData.isLoading ? (
        <Spinner className="m-auto" />
      ) : (
        <div className="flex flex-grow flex-col gap-3">
          <div className="nodrag flex items-center gap-2">
            <Select
              value={type}
              disabled={isExecution}
              onChange={(value) => {
                if (value === REQUEST_INTEGRATION) {
                  window.open(kIntegrationRequest, '_blank');
                } else {
                  updateNodeData(id, { type: value, fieldData: {} });
                }
              }}
              full
              options={[
                ...options.map((ii) => ({
                  value: ii.key,
                  label: ii.name,
                  icon: (
                    <div className="relative">
                      {connections.get(ii.key) && (
                        <Chip
                          className="-left-1 -top-1 absolute"
                          type="dot"
                          color={
                            connections.get(ii.key)?.error ? 'red' : 'green'
                          }
                        />
                      )}
                      <img
                        src={ii.logoUri}
                        alt={`${ii.name} ${intl.formatMessage({ defaultMessage: 'Logo' })}`}
                        height={16}
                        width={16}
                        className="overflow-hidden rounded-sm"
                      />
                    </div>
                  ),
                })),
                {
                  value: 'email',
                  label: intl.formatMessage({
                    defaultMessage: 'Email yourself',
                  }),
                  icon: <Mail size="1rem" />,
                },
                {
                  divider: 'top',
                  value: REQUEST_INTEGRATION,
                  selectable: false,
                  label: intl.formatMessage({
                    defaultMessage: 'Request an integration',
                  }),
                  icon: <ExternalLink size="1rem" />,
                },
              ]}
            />
            {connections &&
              isConnected &&
              !isExecution &&
              type &&
              type !== 'email' && (
                <Menu>
                  <Menu.Trigger>
                    <Button variant="naked">
                      {disconnect.isMutating ? (
                        <Spinner />
                      ) : (
                        <Ellipsis size="1rem" />
                      )}
                    </Button>
                  </Menu.Trigger>
                  <Menu.Item
                    onClick={() => {
                      updateNodeData(
                        id,
                        {
                          type,
                        },
                        { replace: true }
                      );
                      disconnect.trigger();
                    }}
                  >
                    <FormattedMessage defaultMessage="Disconnect" id="qj1uhz" />
                  </Menu.Item>
                </Menu>
              )}
          </div>
          {type === 'email' ? (
            <EmailSelect
              node={props}
              value={data.email}
              workflowId={workflowId}
              disabled={isExecution || isPreview}
              onChange={(next) =>
                updateNodeData(id, {
                  [type]: { ...next },
                })
              }
            />
          ) : (
            <>
              {type && !isConnected && (
                <ConnectionForm key={type} type={type} />
              )}
              {dataSource.isLoading ? (
                <Spinner className="m-auto" />
              ) : (
                <>
                  {parametersSchema && (
                    <ConfigForm
                      schema={parametersSchema}
                      uiSchema={formOverrides?.config}
                      type={type}
                      instanceKey={instanceKey}
                      collectionKey={collectionKey}
                      value={collectionParameters}
                      onChange={(next) =>
                        updateNodeData(id, { parameterData: next })
                      }
                      disabled={isExecution}
                    />
                  )}
                  {fieldsSchema && (
                    <JsonForm
                      autocomplete={autocomplete}
                      schema={fieldsSchema}
                      value={fieldData}
                      disabled={isExecution}
                      formOverride={formOverrides}
                      onChange={(next) =>
                        updateNodeData(id, { fieldData: next })
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </SingleSourceNode>
  );
}

function ConnectionForm(props: { type: string }) {
  const intl = useIntl();
  const { type } = props;
  const integration = useIntegrationGet(type);
  const [value, onChange] = useState<unknown>({});
  const connect = useConnectionCreate(type, value);

  if (integration.isLoading) return <Spinner className="m-auto" />;

  if (!integration.data) return null;
  const { name, authOptions, logoUri } = integration.data;

  return (
    <div className="tq-dashed-border flex flex-grow flex-col items-center justify-center gap-4 p-6 px-10">
      <div className="flex items-center justify-center gap-3">
        <img
          className="h-12 w-12"
          src={logo}
          alt={intl.formatMessage({ defaultMessage: 'Tactiq Logo' })}
        />
        <ArrowRightLeft className="size-6 stroke-slate-800" />
        <img
          src={logoUri}
          alt={`${name} ${intl.formatMessage({ defaultMessage: 'Logo' })}`}
          height={48}
          width={48}
          className="overflow-hidden rounded-lg"
        />
      </div>
      <div className="text-center font-bold text-lg">
        <FormattedMessage
          defaultMessage="Connect Tactiq to {name}"
          id="4YYBtp"
          values={{ name }}
        />
      </div>
      {authOptions?.map((item) => {
        // integration app's types here are not correct. They think
        // ui does not exist even though it clearly does.
        const auth = item as { key: string; ui?: { schema: DataSchema } };
        return (
          <React.Fragment key={auth.key}>
            <JsonForm
              disabled={false}
              schema={auth.ui?.schema ?? { type: 'object' }}
              value={value}
              onChange={onChange}
            />
            <Button
              variant="secondaryOutline"
              loading={connect.isMutating}
              onClick={async () => {
                try {
                  await connect.trigger(auth.key);
                } catch (e) {
                  enqueueSnackbar(e.message, {
                    variant: 'ERROR',
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                  });
                }
              }}
            >
              <FormattedMessage defaultMessage="Allow access" id="YXzlhm" />
            </Button>
          </React.Fragment>
        );
      })}
    </div>
  );
}

const ConfigForm: React.FC<{
  type: string;
  instanceKey: string;
  schema: DataSchema;
  disabled: boolean;
  value?: Record<string, string>;
  collectionKey?: string;
  uiSchema?: UiSchema;
  onChange: (next: Record<string, string>) => void;
}> = (props) => {
  const { type, value, instanceKey, collectionKey, schema, uiSchema } = props;

  if (!uiSchema) return null;
  if (!collectionKey) return null;

  return (
    <div className="nodrag">
      {uiSchema?.map((item) => {
        const referenceCollectionKey =
          schema.properties?.[item?.path]?.referenceCollection?.key;

        const itemValue = get(value, item.path);
        const itemSchema = jsonSchemaGet(schema, item.path);

        return itemSchema?.referenceCollection?.key ? (
          <CollectionList
            key={item.path}
            disabled={props.disabled}
            type={type}
            instanceKey={instanceKey}
            schema={item}
            collectionKey={collectionKey}
            referenceCollectionKey={referenceCollectionKey}
            value={itemValue}
            onChange={(next) =>
              props.onChange(set(value ?? {}, item.path, next))
            }
          />
        ) : null;
      })}
    </div>
  );
};

const CollectionList: React.FC<{
  type: string;
  instanceKey: string;
  schema: UiSchemaItem;
  collectionKey: string;
  referenceCollectionKey: string;
  value: string | undefined;
  disabled: boolean;
  onChange: (next: string) => void;
}> = (props) => {
  const {
    type,
    instanceKey,
    schema,
    collectionKey,
    referenceCollectionKey,
    disabled,
  } = props;
  const list = useDataCollectionList({
    type,
    collectionKey: referenceCollectionKey,
  });
  const update = useDataSourceUpdate({
    type,
    collectionKey,
    instanceKey,
  });
  const refreshDataSource = useDataSourceRefresh({ type, instanceKey });
  const dataSource = useDataSource({ type, instanceKey });

  const options = list.data?.records ?? [];
  const refreshData = async () => {
    await Promise.all([list.mutate(), refreshDataSource.trigger()]);
  };

  if (!update) return null;
  return (
    <>
      <div className="mb-2 flex w-full min-w-32 flex-row items-center font-medium text-sm">
        <p>{schema.label?.()}</p>
        <Button
          variant="naked"
          startIcon={<RefreshIcon className="size-4" />}
          loading={
            list.isLoading ||
            update.isMutating ||
            refreshDataSource.isMutating ||
            dataSource.isValidating
          }
          disabled={
            list.isLoading ||
            update.isMutating ||
            refreshDataSource.isMutating ||
            dataSource.isValidating ||
            disabled
          }
          onClick={refreshData}
        />
      </div>

      <Combobox
        multiple={false}
        full={true}
        buttonClasses="w-full"
        disabled={list.isLoading || refreshDataSource.isMutating || disabled}
        id={(x) => x.id}
        name={(x) => x.name ?? x.id}
        value={options.find((x) => x.id === props.value)}
        options={sortBy(options, (x) => x.name)}
        onChange={async (next) => {
          if (!next?.id) return null;
          props.onChange(next.id);
          await update.trigger({ [schema.path]: next.id });
        }}
      />
    </>
  );
};

/**
 * Send email is a legacy node type that integration.app does not provide.
 * So it's patched in to the ui, and is handled separately in the api.
 */
const EmailSelect: React.FC<{
  value?: { subject: string; message: string };
  onChange: (options: { subject: string; message: string }) => void;
  node: WorkflowNodeProps<unknown>;
  disabled: boolean;
  workflowId: string;
}> = (props) => {
  const intl = useIntl();
  const { onChange, value, disabled, workflowId } = props;
  const autocomplete = useAutocomplete(props.node);
  const { message = '', subject = '' } = value ?? {};
  const subjectInputId = useId();
  const bodyInputId = useId();

  return (
    <div className="flex flex-grow flex-col">
      <div className="flex flex-col gap-y-1">
        <label
          htmlFor={subjectInputId}
          className="block font-medium text-slate-500 text-sm leading-6"
        >
          <FormattedMessage defaultMessage="Subject" id="LLtKhp" />
        </label>
        <LiquidTemplateInput
          className="mb-3 min-h-14 text-base"
          properties={autocomplete.properties}
          variables={autocomplete.variables}
          nodeType="SendData"
          disabled={disabled}
          workflowId={workflowId}
          value={subject}
          onChange={(next) => onChange({ message, subject: next })}
          ariaLabel={intl.formatMessage({
            defaultMessage: 'Email subject input',
          })}
        />
      </div>
      <div className="flex flex-grow flex-col gap-y-1">
        <label
          htmlFor={bodyInputId}
          className="block font-medium text-slate-500 text-sm leading-6"
        >
          <FormattedMessage defaultMessage="Message" id="T7Ry38" />
        </label>
        <LiquidTemplateInput
          className="min-h-24 basis-1/2 text-base"
          properties={autocomplete.properties}
          variables={autocomplete.variables}
          nodeType="SendData"
          disabled={disabled}
          workflowId={workflowId}
          value={value?.message ?? ''}
          onChange={(next) => onChange({ subject, message: next })}
          ariaLabel={intl.formatMessage({
            defaultMessage: 'Email message input',
          })}
        />
      </div>
    </div>
  );
};
